import { action, observable } from "mobx";
import ComplainService from "../services/complainService";
import FormService from "../services/formService";
import authStore from "./authStore";
import { act } from "react-dom/test-utils";
import CommonService from "../services/commonService";

interface ThreePhaseFilesInterface {
  id: number;
  doc: string;
  name: string;
  optional: boolean;
  domesticOptional: boolean;
  multiple: boolean;
  multipleLabels: any[];
  multipleMax: number;
  sampleFileUrl: string;
  domestic: boolean;
  domesticName: string;
  industrial: boolean;
}

interface EstimationSetValueInterface {
  title: string;
  value: number;
  message: string;
}

export class ComplainStore {
  @observable
  public navTabLists: Array<any> = [
    {
      id: 1,
      name: "Complains",
      description: "Nepal Electricity Authority Complains.",
      badge: null,
      logo: "ComplainLogo",
    },
    {
      id: 2,
      name: "Power Supply",
      description: "Power Supply Information.",
      badge: null,
      logo: "FeederLogo",
    },
    {
      id: 3,
      name: "New Connection",
      description: "New Connection Section",
      badge: null,
      logo: "NewConnectionImage",
    },
    {
      id: 4,
      name: "Information",
      description: "All Informations.",
      badge: null,
      logo: "InformationLogo",
    },
    {
      id: 5,
      name: "Reports",
      description: "Reports Section",
      badge: null,
      logo: "ReportLogo",
    },
    {
      id: 6,
      name: "Settings",
      description: "Settings Section",
      badge: null,
      logo: "SettingLogo",
    },
  ];
  public complainStatusLists: Array<object> = [
    {
      value: 0,
      title: "Pending",
    },
    {
      value: 2,
      title: "In Progress",
    },
    {
      value: 1,
      title: "Resolved",
    },
  ];
  public feederStatusLists: Array<object> = [
    {
      value: 0,
      title: "Healthy",
    },
    {
      value: 1,
      title: "Fault",
    },
    {
      value: 2,
      title: "Maintenance",
    },
  ];
  @observable public activeNavTabId: number | undefined = 0;
  @observable public activeStatus: number | string = "";
  @observable public complainLists: Array<object> = [];
  @observable public complainListsOtherDetails: any = {
    currentPage: 1,
    total: 0,
    count: 0,
    status: 0,
  };
  @observable public feederLists: Array<object> = [];
  @observable public feederIssueLists: Array<object> = [];
  @observable public formDataLists: Array<object> = [];
  @observable public rerenderComplainList: boolean = false;
  @observable public rerenderFeederIssueList: boolean = false;
  @observable public complainCount: number = -1;
  @observable public feederCount: number = -1;

  // for data count
  @observable public dataCount: any = {};

  @observable public complainFilterAppliedLists: any = {
    province: "",
    feeder_name: "",
    district: "",
    municipility: "",
    branch: "",
    status: "",
    mobile: "",
    from_date: "",
    to_date: "",
    page: 1,
    reg_no: "",
  };
  @observable public feederFilterLists: any = {
    feeder: "",
    status: -1,
    branch: "",
    feeder_name: "",
    municipility: "",
    province: "",
    page: 1,
  };

  @observable public fileLabels: any = [
    "नयाँ ग्राहक आबेदन फारम",
    "ऊधोग् / व्यावसाय / संघ / संस्था दर्ता प्रमाणपत्रको प्रतिलिपि",
    "आयकर दर्ता प्रमाणपत्रको प्रतिलिपि (PAN/VAT Registration)",
    "कर चुक्ता प्रमाणपत्रको प्रतिलिपि (Up to Date)",
    "सरकारी कार्यालय तथा सार्वजनिक संस्थाको आधारिक पत्र",
    "प्रोप्राइटर संचालकहरुको नागरिकताको   प्रतिलिपिहरु",
    "उद्योग, व्यावसाय , संघ वा संस्था संचालन हुने घरको जग्गाधनी प्रमाणपुर्जा र जग्गा रजिस्ट्रेशन पास गरेको लिखित  प्रतिलिपी",
    "उद्योग, व्यावसाय , संघ वा संस्था संचालन हुने घरको नक्सापास प्रमाणपत्रको  प्रतिलिपी",
    "जग्गाधनिसंग भएको घर भाडा सम्झौता, मन्जुरिनामा र निजको ना.प्र.प को प्रतिलिपि",
    "पासपोर्ट साइजको फोटो",
    "महानगरपालिका/नगरपालिका/गाउँपालिकाको सिफारिश पत्र",
    "छिमेकिको मन्जुरीनामा",
    "मोटर तथा उपकरणहरु खरिदको Invoice/Catalogue",
  ];
  @observable public fileType: any = [
    {
      title: "आवेदकको फोटो",
      altTitle: "",
      name: "name",
      file_id: 1,
      required: true,
      inUserType: ["singlePhase"],
    },

    {
      title: "आवेदक संस्थाको छापको फोटो",
      altTitle: "",
      name: "org_stamp",
      file_id: 2,
      required: true,
      inUserType: ["industrialThreePhase"],
    },
    {
      title: "उद्योग / व्यावसाय / संघ / संस्था दर्ता प्रमाणपत्रको प्रतिलिपि",
      altTitle: "",
      name: "org_darta",
      file_id: 3,
      required: true,
      inUserType: [],
    },
    {
      title: "आयकर दर्ता प्रमाणपत्रको प्रतिलिपि (PAN/VAT Registration)",
      altTitle: "",
      name: "org_tax_darta",
      file_id: 4,
      required: true,
      inUserType: ["industrialThreePhase"],
    },
    {
      title: "कर चुक्ता प्रमाणपत्रको प्रतिलिपि (Up to Date)",
      altTitle: "",
      name: "org_tax_paid",
      file_id: 5,
      required: true,
      inUserType: ["industrialThreePhase"],
    },
    {
      title: "अगाडि",
      altTitle: "",
      name: "nagarikta_front",
      file_id: 6,
      required: true,
      inUserType: ["singlePhase", "industrialThreePhase"],
    },
    {
      title: "पछाडि",
      altTitle: "",
      name: "nagarikta_back",
      file_id: 7,
      required: true,
      inUserType: ["singlePhase", "industrialThreePhase"],
    },
    {
      title: "घर/जग्गाको धनिपुर्जा",
      altTitle:
        "उद्योग / व्यावसाय / संघ / संस्था संचालन हुने घर/जग्गाको धनिपुर्जा",
      name: "land_ownership",
      file_id: 8,
      required: true,
      inUserType: ["singlePhase", "industrialThreePhase"],
    },
    {
      title: "नागरिकता को प्रतिलिपि(अगाडि)",
      altTitle: "",
      name: "rent_nagarikta_front",
      file_id: 9,
      required: true,
      inUserType: ["propertyRented"],
    },
    {
      title: "मन्नजुरीनामाको प्रतिलिपि",
      altTitle: "",
      name: "rent_manjurinama",
      file_id: 10,
      required: true,
      inUserType: ["propertyRented"],
    },
    {
      title: "सम्झौता पत्रको प्रतिलिपि",
      altTitle: "",
      name: "rent_agreement",
      file_id: 11,
      required: true,
      inUserType: ["propertyRented"],
    },
    {
      title:
        "अंशवण्डा वा अन्य मुद्धा परी अदालतबाट फैसाला भैसकेको भए फैसालाको प्रमाणित प्रतिलिपि नभए तारेखपर्चाको प्रमाणित प्रतिलिपि",
      altTitle: "",
      name: "angshabanda_file",
      file_id: 12,
      required: true,
      inUserType: ["landDisputed"],
    },
    {
      title:
        "अन्य व्याक्तिको घर जग्गा भएर विद्धुत लाइन्, तार लग्नुपर्ने भएमा घर,जग्गा धनीको मंजुरीनामा",
      altTitle: "",
      name: "from_other_file",
      file_id: 13,
      required: true,
      inUserType: ["connectionFromOtherPeople"],
    },
    {
      title:
        "निजि ट्रान्सफर्मर जडान लाईन निर्माण कार्य ग्राहक सवयंले सम्पन्न गरेको भए ने.वि.प्रा को स्थानीय कार्यलयसंग निर्माण कार्यको लागी लिएको अनुमतिपत्र",
      altTitle: "",
      name: "transformer_permit",
      file_id: 14,
      required: true,
      inUserType: [],
    },
    {
      title: "Invoice",
      altTitle: "",
      name: "transformer_buy",
      file_id: 15,
      required: true,
      inUserType: ["transformer"],
    },
    {
      title: "Catalogue",
      altTitle: "",
      name: "transformer_cat",
      file_id: 16,
      required: true,
      inUserType: ["transformer"],
    },
    {
      title: "Test Certificate",
      altTitle: "",
      name: "transformer_test_cert",
      file_id: 17,
      required: true,
      inUserType: ["transformer"],
    },
    {
      title: "मा.न.पा/ न.पा / गा.पा को सिफारिस पत्र",
      altTitle: "",
      name: "town_recommend",
      file_id: 18,
      required: true,
      inUserType: ["singlePhase"],
    },
    {
      title: "सरकारी कार्यलय तथा संस्थाको हकमा आधिकारिक पत्र",
      altTitle: "",
      name: "goverment_official_file",
      file_id: 19,
      required: true,
      inUserType: [],
    },
    {
      title: "उधोग वयावसायको प्रबन्धपत्र",
      altTitle: "",
      name: "industry_prabandha",
      file_id: 20,
      required: true,
      inUserType: [],
    },
    {
      title: "भवन निर्माण कार्यको अनुमतिपत्रको प्रतिलिपि (नक्सा पास)",
      altTitle: "",
      name: "building_permit",
      file_id: 21,
      required: true,
      inUserType: ["singlePhase"],
    },
    {
      title: "नागरिकता को प्रतिलिपि(पछाडि)",
      altTitle: "",
      name: "rent_nagarikta_back",
      file_id: 22,
      required: true,
      inUserType: ["propertyRented"],
    },
  ];

  @observable public threePhaseFiles: ThreePhaseFilesInterface[] = [
    {
      id: 1,
      name: "आवेदकको फोटो",
      doc: "doc_1",
      multiple: true,
      optional: false,
      domesticOptional: false,
      multipleLabels: [],
      multipleMax: 4,
      domestic: true,
      domesticName: "आवेदकको फोटो",
      industrial: true,
      sampleFileUrl:
        "https://www.nea.org.np/admin/assets/uploads/supportive_docs/New%20Application%20form%20Final.pdf",
    },
    {
      id: 2,
      name: "नयाँ ग्राहक आवेदन फारम",
      doc: "doc_2",
      multiple: false,
      optional: false,
      domesticOptional: false,
      multipleLabels: [],
      multipleMax: 0,
      domestic: true,
      domesticName: "नयाँ ग्राहक आवेदन फारम",
      industrial: true,
      sampleFileUrl:
        "https://www.nea.org.np/admin/assets/uploads/supportive_docs/New%20Application%20form%20Final.pdf",
    },
    {
      id: 3,
      name: "उद्योग / व्यावसाय / संघ / संस्था दर्ता प्रमाणपत्रको प्रतिलिपि",
      multiple: false,
      optional: false,
      domesticOptional: false,
      doc: "doc_3",
      multipleLabels: [],
      multipleMax: 0,
      domestic: false,
      domesticName: "",
      industrial: true,
      sampleFileUrl:
        "https://www.nea.org.np/admin/assets/uploads/supportive_docs/New%20Application%20form%20Final.pdf",
    },
    {
      id: 4,
      name: "आयकर दर्ता प्रमाणपत्रको प्रतिलिपि (PAN/VAT Registration)",
      optional: true,
      domesticOptional: true,
      multiple: false,
      doc: "doc_4",
      multipleLabels: [],
      multipleMax: 0,
      domestic: false,
      domesticName: "",
      industrial: true,
      sampleFileUrl:
        "https://www.nea.org.np/admin/assets/uploads/supportive_docs/New%20Application%20form%20Final.pdf",
    },
    {
      id: 5,
      name: "कर चुक्ता प्रमाणपत्रको प्रतिलिपि (Up to Date)",
      doc: "doc_5",
      optional: true,
      domesticOptional: true,
      multiple: false,
      multipleLabels: [],
      multipleMax: 0,
      domestic: false,
      domesticName: "",
      industrial: true,
      sampleFileUrl:
        "https://www.nea.org.np/admin/assets/uploads/supportive_docs/New%20Application%20form%20Final.pdf",
    },
    {
      id: 6,
      name: "प्रोप्राइटर / संचालकहरुको नागरिकता प्रमाणपत्रको प्रतिलिपि",
      doc: "doc_6",
      optional: false,
      domesticOptional: false,
      multiple: true,
      multipleLabels: [],
      multipleMax: 4,
      domestic: true,
      domesticName: "नागरिकता प्रमाणपत्रको प्रतिलिपि",
      industrial: true,
      sampleFileUrl:
        "https://www.nea.org.np/admin/assets/uploads/supportive_docs/New%20Application%20form%20Final.pdf",
    },
    {
      id: 7,
      name: "उद्योग / व्यावसाय / संघ / संस्था संचालन हुने घर/जग्गाको धनिपुर्जा",
      doc: "doc_7",
      optional: false,
      domesticOptional: false,
      multiple: false,
      multipleLabels: [],
      multipleMax: 0,
      domestic: true,
      domesticName: "घर/जग्गाको धनिपुर्जा",
      industrial: true,
      sampleFileUrl:
        "https://www.nea.org.np/admin/assets/uploads/supportive_docs/New%20Application%20form%20Final.pdf",
    },
    {
      id: 8,
      name: "भाडामा लिएको खण्डमा घर/ जग्गा धनीको",
      doc: "doc_8",
      optional: true,
      domesticOptional: true,
      multiple: true,
      domestic: true,
      domesticName: "भाडामा लिएको खण्डमा घर/जग्गा धनीको",
      industrial: true,
      multipleLabels: [
        "नागरिकता को प्रतिलिपि",
        "मन्नजुरीनामाको प्रतिलिपि",
        "सम्झौता पत्रको प्रतिलिपि",
      ],
      multipleMax: 0,
      sampleFileUrl:
        "https://www.nea.org.np/admin/assets/uploads/supportive_docs/New%20Application%20form%20Final.pdf",
    },
    {
      id: 9,
      doc: "doc_9",
      name: "अंशवण्डा मुद्धा परी अदालतबाट फैसाला भैसकेको भए फैसालाको प्रमाणित प्रतिलिपि नभए तारेखपर्चाको प्रमाणित प्रतिलिपि",
      optional: true,
      domesticOptional: true,
      multiple: false,
      multipleLabels: [],
      multipleMax: 0,
      domestic: true,
      industrial: false,
      domesticName:
        "अंशवण्डा मुद्धा परी अदालतबाट फैसाला भैसकेको भए फैसालाको प्रमाणित प्रतिलिपि नभए तारेखपर्चाको प्रमाणित प्रतिलिपि",
      sampleFileUrl:
        "https://www.nea.org.np/admin/assets/uploads/supportive_docs/New%20Application%20form%20Final.pdf",
    },
    {
      id: 10,
      doc: "doc_10",
      name: "अन्य व्याक्तिको घर जग्गा भएर विद्धुत लाइन्, तार लग्नुपर्ने भएमा घर,जग्गा धनीको मंजुरीनामा ",
      optional: true,
      domesticOptional: true,
      multiple: false,
      multipleLabels: [],
      multipleMax: 0,
      domestic: true,
      domesticName:
        "अन्य व्याक्तिको घर जग्गा भएर विद्धुत लाइन्, तार लग्नुपर्ने भएमा घर,जग्गा धनीको मंजुरीनामा",
      industrial: true,
      sampleFileUrl:
        "https://www.nea.org.np/admin/assets/uploads/supportive_docs/New%20Application%20form%20Final.pdf",
    },
    {
      id: 11,
      doc: "doc_11",
      name: "निजि ट्रान्सफर्मर जडान लाईन निर्माण कार्य ग्राहक सवयंले सम्पन्न गरेको भए ने.वि.प्रा को स्थानीय कार्यलयसंग निर्माण कार्यको लागी लिएको अनुमतिपत्र",
      optional: true,
      domesticOptional: true,
      multiple: false,
      multipleLabels: [],
      multipleMax: 0,
      domestic: false,
      domesticName: "",
      industrial: true,
      sampleFileUrl:
        "https://www.nea.org.np/admin/assets/uploads/supportive_docs/New%20Application%20form%20Final.pdf",
    },
    {
      id: 12,
      doc: "doc_12",
      name: "मोटर / उपकरण / लाईन निर्माण सामाग्री / ट्रान्सफर्मर खरिदको",
      optional: true,
      domesticOptional: true,
      multiple: true,
      multipleLabels: ["Invoice", "Catalogue", "Test Certificate"],
      multipleMax: 4,
      domestic: false,
      domesticName: "",
      industrial: true,
      sampleFileUrl:
        "https://www.nea.org.np/admin/assets/uploads/supportive_docs/New%20Application%20form%20Final.pdf",
    },
    {
      id: 13,
      doc: "doc_13",
      name: "मा.न.पा/ न.पा / गा.पा को सिफारिस पत्र",
      optional: true,
      domesticOptional: true,
      multiple: false,
      multipleLabels: [],
      multipleMax: 0,
      domestic: true,
      domesticName: "मा.न.पा/ न.पा / गा.पा को सिफारिस पत्र",
      industrial: true,
      sampleFileUrl:
        "https://www.nea.org.np/admin/assets/uploads/supportive_docs/New%20Application%20form%20Final.pdf",
    },
    {
      id: 14,
      doc: "doc_14",
      name: "सरकारी कार्यलय तथा संस्थाको हकमा आधिकारिक पत्र",
      optional: true,
      domesticOptional: true,
      multiple: false,
      multipleLabels: [],
      multipleMax: 0,
      domestic: false,
      domesticName: "",
      industrial: true,
      sampleFileUrl:
        "https://www.nea.org.np/admin/assets/uploads/supportive_docs/New%20Application%20form%20Final.pdf",
    },
    {
      id: 15,
      name: "उधोग वयावसायको प्रबन्धपत्र",
      doc: "doc_15",
      optional: true,
      domesticOptional: true,
      multiple: false,
      multipleLabels: [],
      multipleMax: 0,
      domestic: false,
      domesticName: "",
      industrial: true,
      sampleFileUrl:
        "https://www.nea.org.np/admin/assets/uploads/supportive_docs/New%20Application%20form%20Final.pdf",
    },
    {
      id: 16,
      name: "भवन निर्माण कार्यको अनुमतिपत्रको प्रतिलिपि (नक्सा पास)",
      doc: "doc_16",
      optional: true,
      domesticOptional: true,
      multiple: false,
      multipleLabels: [],
      multipleMax: 0,
      domestic: true,
      domesticName: "भवन निर्माण कार्यको अनुमतिपत्रको प्रतिलिपि (नक्सा पास)",
      industrial: true,
      sampleFileUrl:
        "https://www.nea.org.np/admin/assets/uploads/supportive_docs/New%20Application%20form%20Final.pdf",
    },
  ];

  @observable public estimationSetValue: EstimationSetValueInterface[] = [
    {
      title: "माग गरेको स्थानमा बिधुत बितरण संरचना नभएकोले।",
      value: 2,
      message:
        "तपाईले विद्युत माग गरेको स्थानमा, विद्युत वितरण संरचना नरहेकोले हाललाई तपाईको माग वमोजिमको विद्युत उपलब्ध गराउन नसकिने व्यहोरा अनुरोध छ . बिस्तृत जानकारीको लागि कार्यालयमा सम्पर्क गर्नुहोला, धन्यवाद",
    },
    {
      title:
        "माग गरेको सथानमा जडित ट्रान्सफर्मर /एल.टि. लइन/फिडरको क्षमता अपुत भएकोले",
      value: 3,
      message:
        "तपाईले विद्युत माग गरेको स्थानमा रहेको विद्युत वितरण संरचनाको क्षमता अपुग भएकोले हाललाई तपाईको माग वमोजिमको विद्युत उपलब्ध गराउन नसकिने व्यहोरा अनुरोध छ . बिस्तृत जानकारीको लागि कार्यालयमा सम्पर्क गर्नुहोला, धन्यवाद ",
    },
    {
      title:
        " विद्धुतको प्रसारण तथा लइन तथा बिधुत ऊपभोग गर्ने घर जग्गा विचको न्युनतम दुरी (clearance) भित्र पर्ने भएकोले",
      value: 4,
      message:
        "तपाईले विद्युत उपभोग गर्ने घर/जग्गा र विद्युत संरचना विच कायम हुनुपर्ने न्यूनतम दुरी कायम नरहेको हुदा तपाईको आवेदन हाल लाई स्थगित गरिएको छ . बिस्तृत जानकारीको लागि कार्यालयमा सम्पर्क गर्नुहोला, धन्यवाद ",
    },
    {
      title:
        "जडान हुने घर जग्गाले कम्पउण्डभित्र या निज घर जग्गा धनीले अन्यात्र उपभोग गराको विद्धुत शक्तिको वाकी वक्यौता नतिरेको ,विद्धुत शक्तिको अनाधीकार उपभोग वा चोरी गरी महशुल/दस्तुर/जरवाना अन्य कुनै तिर्न बुझुन पर्ने रकम नबुझाएकोले। ",
      value: 5,
      message:
        "विद्युत उपभोग हुने घर जग्गा धनिको परिवार या संस्थाले विद्युत उपभोग बापतको महशुल/जरीवाना/तिर्न बुझाउन पर्ने रकम नबुझाएको हुदा तपाईको आवेदन हाल लाई स्थगित गरिएको छ . बिस्तृत जानकारीको लागि कार्यालयमा सम्पर्क गर्नुहोला, धन्यवाद . ",
    },
    {
      title:
        "माग गरेको गर जग्गामा १०० के.भि.ए लोड न.बि.प्रा को ट्रान्सफर्मरवाटा स्विकत  भएकोले।",
      value: 6,
      message:
        " तपाईले विद्युत उपभोग गर्न माग गरेको घर जग्गामा ने.वि.प्रा. को ट्रान्सफरमर बाट १०० के.भी.ए. लोड जडान भईसकेकोले तपाईको आवेदन हाल लाई स्थगित गरिएको छ . बिस्तृत जानकारीको लागि कार्यालयमा सम्पर्क गर्नुहोला, धन्यवाद .",
    },
    {
      title: "अन्य।",
      value: 7,
      message: " अन्य।",
    },
  ];

  @action
  public init = () => {
    this.activeNavTabId = 0;
    this.complainLists = [];
    this.feederLists = [];
    this.feederIssueLists = [];
    this.formDataLists = [];
    this.rerenderComplainList = false;
    this.rerenderFeederIssueList = false;

    this.dataCount = {};
  };

  @action
  public changeActiveTabId = (id: number, status) => {
    this.activeNavTabId = id;
    // this.activeStatus = status;
    // this.pullDataBasedOnTabId(id);
  };
  public pullDataBasedOnTabId = (id) => {
    parseInt(id) === 1 && this.getComplainLists(true);
    parseInt(id) === 2 && this.getFeederIssueLists(true);
  };
  @action
  public updateComplainList = (data) => {
    this.complainLists = data;

    this.rerenderComplainList = !this.rerenderComplainList;
  };
  @action
  public updateFeederIssueListList = (data) => {
    this.feederIssueLists = data;
    this.rerenderFeederIssueList = !this.rerenderFeederIssueList;
  };
  @action
  public updateFeederListList = (data) => {
    this.feederLists = data;
    this.rerenderFeederIssueList = !this.rerenderFeederIssueList;
  };
  @action
  public updatereRenderFeeder = () => {
    this.rerenderFeederIssueList = !this.rerenderFeederIssueList;
  };

  @action
  public setCoplainFilterApplied = async (filters) => {
    this.complainFilterAppliedLists.page = 1;
    this.complainFilterAppliedLists = filters;
  };

  @action
  public getComplainLists = async (repull = false, filters: any = null) => {
    if (!!filters) {
      if (this.complainListsOtherDetails.status != filters.status) {
        filters.page = 1;
      }
    }
    this.complainCount = -1;
    const _pullComplain = repull === true || this.complainLists.length === 0;

    const complainResponse: any =
      _pullComplain && (await ComplainService.getComplainLists(filters));

    const _pullFormData = this.formDataLists.length == 0;

    const formResponse: any =
      _pullFormData && (await FormService.getFormData());

    if (_pullFormData && !!formResponse.data) {
      let fDAta = formResponse.data;
      fDAta.branches.sort((a, b) =>
        a.office_title.localeCompare(b.office_title)
      );

      this.formDataLists = formResponse.data;

      this.rerenderComplainList = !this.rerenderComplainList;
    }
    if (!!complainResponse.data) {
      this.complainListsOtherDetails = {
        currentPage: complainResponse.data.current_page,
        total: complainResponse.data.count,
        pendingCount: complainResponse.data.pending_count,
        status: !!filters ? filters.status : 0,
      };
    }

    if (_pullComplain && !!complainResponse.data) {
      this.complainLists = complainResponse.data.complain;

      this.navTabLists = this.navTabLists.map((nav) => {
        let tempNav: any = nav;
        if (tempNav.id === 1) {
          tempNav.badge = complainResponse.data.pending_count;
        }
        return tempNav;
      });
    }

    this.complainCount = this.complainLists.length;
  };
  @action
  public getFormLists = async () => {
    const formResponse: any = await FormService.getFormData();
    this.formDataLists = formResponse.data;
  };
  @action
  public emptyComplainLists = () => {
    this.complainLists = [];
  };
  @action
  public getFeederIssueLists = async (repull = false) => {
    this.complainCount = -1;
    const _pull = repull === true || this.feederLists.length === 0;

    const _pullFormData = this.formDataLists.length == 0;
    const formResponse2: any =
      _pullFormData && (await FormService.getFormData());
    if (_pullFormData && !!formResponse2.data) {
      let fDAta = formResponse2.data;
      fDAta.branches.sort((a, b) =>
        a.office_title.localeCompare(b.office_title)
      );
      this.formDataLists = formResponse2.data;
    }

    const formResponse: any = _pull && (await FormService.getFeederLists());
    if (_pull && typeof formResponse.data !== "undefined") {
      this.feederLists = formResponse.data;
      this.rerenderFeederIssueList = !this.rerenderFeederIssueList;
    }
    this.feederCount = this.feederLists.length;
  };
  @action
  public setFormDataLists(data) {
    this.formDataLists = data;
  }
  @action
  public updateFeederFilter = (updatedFeederStatus) => {
    this.feederFilterLists = updatedFeederStatus;
  };

  @action
  public getDataCount = async () => {
    try {
      const lists: any = await CommonService.getCountReports();
      if (lists.status === 200) {
        this.dataCount = {
          ...lists.data.data,
        };
        this.navTabLists = this.navTabLists.map((nav) => {
          let tempNav: any = nav;
          if (tempNav.id === 1) {
            tempNav.badge = this.dataCount.pending;
          }
          if (tempNav.id === 2) {
            tempNav.badge = this.dataCount.fault ? this.dataCount.fault : null;
          }
          if (tempNav.id === 3) {
            tempNav.badge =
              this.dataCount.new_pending_threephase &&
              this.dataCount.new_pending_singlephase
                ? this.dataCount.new_pending_threephase +
                  this.dataCount.new_pending_singlephase
                : this.dataCount.new_pending_threephase
                ? this.dataCount.new_pending_threephase
                : this.dataCount.new_pending_singlephase
                ? this.dataCount.new_pending_singlephases
                : null;
          }
          return tempNav;
        });
        return lists.data.data;
      }
    } catch (err) {}
  };
  // init = autorun(() => {
  //   authStore.isAuthorized && this.getComplainLists();
  // });
}
export default new ComplainStore();
