import { action, observable, autorun } from "mobx";
import { isMobile } from "react-device-detect";
import CommonService from "../services/commonService";
import { act } from "react-dom/test-utils";
import FormService from "../services/formService";

export class CommonStore {
  @observable public isMobile: any = isMobile;
  @observable public branchLists: Array<any> = [];
  @observable public contactNumberLists: Array<any> = [];
  @observable public subjectLists: Array<any> = [];
  @observable public subStationLists: Array<any> = [];
  @observable public userLists: Array<any> = [];
  @observable public formRelatedData: any = [];
  @observable public isContactNumberPulled = false;
  public ContactNumberStatusLists: any = [
    {
      title: "Just to list",
      value: 0,
    },
    {
      title: "Send Message",
      value: 1,
    },
    {
      title: "InActive Number",
      value: 2,
    },
  ];
  public SubjectStatusLists: any = [
    {
      title: "Inactive",
      value: 0,
    },
    {
      title: "Active",
      value: 1,
    },
  ];
  init = autorun(() => {
    this.isMobile = isMobile;
  });
  @action
  public getBranchLists = async () => {
    try {
      const formResponse: any = await FormService.getFormData();
      this.formRelatedData = formResponse.data;
      const res = await CommonService.getBranchLists();
      this.branchLists = res.data;
    } catch (e) {}
  };
  @action
  public getContactNumberLists = async (id = false) => {
    try {
      this.isContactNumberPulled = false;
      const res = await CommonService.getContactNumberLists(id);
      if (!!id) {
        return res;
      }
      this.isContactNumberPulled = true;
      this.contactNumberLists = res.data;
    } catch (e) {}
  };

  @action
  public getSubjectLists = async (id = false) => {
    this.isContactNumberPulled = false;

    const res = await CommonService.getSubjectLists(id);

    if (!!id) {
      return res;
    }

    this.subjectLists = res.data;
  };
  @action
  public getSubStationLists = async () => {
    try {
      const res = await CommonService.getSubStationLists();
      this.subStationLists = res.data;
    } catch (e) {}
  };
  @action
  public getUserLists = async (id = false) => {
    try {
      const formResponse: any = await FormService.getFormData();
      this.formRelatedData = formResponse.data;
      const res = await CommonService.getUserLists(id);
      if (id) {
        return res.data.data;
      }
      this.userLists = res.data.users;
    } catch (e) {}
  };
}
export default new CommonStore();
