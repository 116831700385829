import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from "axios";
import authStore from "../stores/authStore";
// const API_ROOT: string = "https://banil.com.np/nea/nea"; //"https://crmapi.nea.org.np"; // process.env.REACT_APP_NEA_API_URL || "";
export const API_ROOT: string = "https://neaapi.inferica.com"; // process.env.REACT_APP_NEA_API_URL || ""; // https://neaapi.inferica.com // https://crmapi.nea.org.np
axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const authToken = authStore.getAuthToken();

    if (!!authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    config.headers["Content-Type"] = `application/json`;
    config.headers["Accept"] = `application/json`;
    return config;
  },
  (error: any) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    const { status }: any = error.response || 0;
    if (status === 500) {
      alert("Something went wrong. Please contact Technical Team.");
      return error.response;
    }
    if (status === 401) {
      if (error.response) {
        return Promise.reject(error.response);
      }
      return Promise.reject(error.response);
    }

    return error.response;
  }
);

export const requests = {
  del: (url: string) => axios.delete(API_ROOT + url),
  get: (url: string) => axios.get(API_ROOT + url),
  post: (url: string, data?: any, contentType?: any) => {
    const config = {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    };
    if (contentType) {
      return axios.post(API_ROOT + url, data, config);
    } else {
      return axios.post(API_ROOT + url, data);
    }
  },
  put: (url: string, data?: any, contentType?: any) => {
    const config = {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    };
    if (contentType) {
      return axios.put(API_ROOT + url, data, config);
    } else {
      return axios.put(API_ROOT + url, data);
    }
  },

  patch: (url: string, data?: any) => axios.patch(API_ROOT + url, data),

  newget: (url: string) => axios.get(url),
};
