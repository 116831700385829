import React, { memo } from "react";
import { observer, inject } from "mobx-react";
import { AuthStore } from "./stores/authStore";
import LoadingView from "./views/loadingView";

const AuthorizedRoute = React.lazy(() => import("./views/authorized/route"));
const UnAuthorizedRoute = React.lazy(() =>
  import("./views/unauthorized/route")
);

interface RouteProps {
  authStore: AuthStore;
}
const Route = inject("authStore")(
  observer((props: RouteProps) => {
    const { isAuthorized, userInfo } = props.authStore;
    if (isAuthorized === undefined) {
      return <LoadingView />;
    }
    return (
      <React.Suspense fallback={<LoadingView />}>
        {isAuthorized === true && !!userInfo ? (
          <AuthorizedRoute />
        ) : (
          <UnAuthorizedRoute />
        )}
      </React.Suspense>
    );
  })
);
export default memo(Route);
