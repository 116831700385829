import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "mobx-react";

import authStore from "./stores/authStore";
import complainStore from "./stores/complainStore";
import commonStore from "./stores/commonStore";
import { NotificationContainer } from "react-notifications";
const stores = {
  authStore,
  complainStore,
  commonStore,
};

ReactDOM.render(
  <Provider {...stores}>
    <App />
    <NotificationContainer />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.register({
//   onUpdate: registration => {
//     const waitingServiceWorker = registration.waiting;
//     if (waitingServiceWorker) {
//       waitingServiceWorker.addEventListener("statechange", event => {
//         if (event.target.state === "activated") {
//           window.location.reload();
//         }
//       });
//       waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
//     }
//   }
// });
