import axios, { AxiosRequestConfig } from "axios";
import { API_ROOT, requests } from "./requests";
export class FormService {
  private static FORM_API: string =
    process.env.REACT_APP_NEW_API_FORMDATA || "";
  private static COMPLAIN_API: string =
    process.env.REACT_APP_NEW_API_COMPLAIN_LISTS || "";
  private static UPDATE_COMPLAIN_API: string =
    process.env.REACT_APP_NEW_API_UPDATE_COMPLAIN || "";
  private static FEEDER_SAVE_API: string =
    process.env.REACT_APP_NEW_API_FEEDER_LISTS || "";
  private static FEEDER_ISSUE_SAVE_API: string =
    process.env.REACT_APP_NEW_API_FEEDER_ISSUE_LISTS || "";
  private static REACT_APP_NEW_API_FEEDER_LISTS: string =
    process.env.REACT_APP_NEW_API_FEEDER_LISTS || "";
  private static REACT_APP_NEW_API_UPDATE_FEEDER_VALUES: string =
    process.env.REACT_APP_NEW_API_UPDATE_FEEDER_VALUES || "";
  public static FEEDER_UPDATE: string =
    process.env.REACT_APP_NEW_API_FEEDER_UPDATE || "";
  public static REACT_APP_USER_REGISTER_FORM_DATA_API: string =
    process.env.REACT_APP_USER_REGISTER_FORM_DATA_API || "";
  public static REACT_APP_COMPLAIN_REPORT_FILTER: string =
    process.env.REACT_APP_COMPLAIN_REPORT_FILTER || "";
  public static REACT_APP_FEEDER_REPORT_FILTER: string =
    process.env.REACT_APP_FEEDER_REPORT_FILTER || "";
  private static THREEPHASE_API: string =
    process.env.REACT_APP_THREEPHASE_ADD || "";
  private static REACT_APP_THREEPHASE_UPDATE: string =
    process.env.REACT_APP_THREEPHASE_UPDATE || "";

  private static REACT_APP_THREEPHASE_DOWNLOAD: string =
    process.env.REACT_APP_THREEPHASE_DOWNLOAD || "";

  private static REACT_APP_THREEPHASE_REPORT_YEAR: string =
    process.env.REACT_APP_THREEPHASE_REPORT_YEAR || "";

  private static REACT_APP_THREEPHASE_REPORT_PROVINCE: string =
    process.env.REACT_APP_THREEPHASE_REPORT_PROVINCE || "";

  private static REACT_APP_THREEPHASE_FILE_STATUS_UPDATE: string =
    process.env.REACT_APP_THREEPHASE_FILE_STATUS_UPDATE || "";

  private static REACT_APP_THREEPHASE_FILE_ESTIMATION_ADD: string =
    process.env.REACT_APP_THREEPHASE_FILE_ESTIMATION_ADD || "";

  public static REACT_APP_THREEPHASE_REPORT_YEAR_DOWNLOAD: string =
    process.env.REACT_APP_THREEPHASE_REPORT_YEAR_DOWNLOAD || "";

  public static REACT_APP_THREEPHASE_REPORT_PROVINCE_DOWNLOAD: string =
    process.env.REACT_APP_THREEPHASE_REPORT_PROVINCE_DOWNLOAD || "";
  public static REACT_APP_THREEPHASE_FORM_ZIPPED_DOWNLOAD: string =
    process.env.REACT_APP_THREEPHASE_FORM_ZIPPED_DOWNLOAD || "";

  public static REACT_APP_FEEDER_SHUTDOWN: string =
    process.env.REACT_APP_FEEDER_SHUTDOWN || "";

  public static REACT_APP_FEEDER_GRID: string =
    process.env.REACT_APP_FEEDER_GRID || "";

  public static REACT_APP_FEEDER_SHUTDOWN_FILTER: string =
    process.env.REACT_APP_FEEDER_SHUTDOWN_FILTER || "";

  public static REACT_APP_FEEDER_SHUTDOWN_STATUS_UPDATE: string =
    process.env.REACT_APP_FEEDER_SHUTDOWN_STATUS_UPDATE || "";

  public static REACT_APP_FEEDER_SHUTDOWN_REPORT_TYPEWISE: string =
    process.env.REACT_APP_FEEDER_SHUTDOWN_REPORT_TYPEWISE || "";

  public static REACT_APP_FEEDER_SHUTDOWN_REPORT: string =
    process.env.REACT_APP_FEEDER_SHUTDOWN_REPORT || "";

  public static REACT_APP_FEEDER_GRID_REPORT: string =
    process.env.REACT_APP_FEEDER_GRID_REPORT || "";

  private static CHECK_CONTACT_NO_API: string =
    process.env.REACT_APP_CHECK_CONTACT_NO_API || "";

  private static NEW_CONNECTIONS: string =
    process.env.REACT_APP_NEW_CONNECTIONS || "";
  private static NEW_CONNECTION: string =
    process.env.REACT_APP_NEW_CONNECTION || "";

  private static THREEPHASE_CONNECTIONS: string =
    process.env.REACT_APP_THREEPHASE_CONNECTIONS || "";

  private static NEW_CONNECTION_STATUS_UPDATE: string =
    process.env.REACT_APP_NEW_CONNECTION_UPDATE || "";

  private static NOTICE_API: string = process.env.REACT_APP_NOTICE || "";

  private static NEW_CONNECTION_REPORT_YEAR =
    process.env.REACT_APP_NEW_CONNECTION_REPORT_API || "";

  private static NEW_CONNECTION_REPORT_PROVINCE =
    process.env.REACT_APP_NEW_CONNECTION_REPORT_BY_PROVINCE_API || "";

  public getFormData = async () => {
    return await requests.get(FormService.FORM_API);
  };
  public saveComplain = async (request) => {
    return await requests.post(FormService.COMPLAIN_API, request);
  };
  public updateComplain = async (request) => {
    return await requests.post(FormService.UPDATE_COMPLAIN_API, request);
  };
  public updatePhase = async (request) => {
    const formData = new URLSearchParams();
    formData.append("message", request.message);
    formData.append("status", request.status);
    formData.append("threephase_id", request.threephase_id);
    const config = "application/x-www-form-urlencoded";
    return await requests.post(
      FormService.REACT_APP_THREEPHASE_UPDATE,
      formData,
      config
    );
  };
  public saveFeeder = async (request) => {
    return await requests.post(FormService.FEEDER_SAVE_API, request);
  };
  public updateFeeder = async (request) => {
    return await requests.post(FormService.FEEDER_UPDATE, request);
  };
  public getFeederLists = async () => {
    return await requests.get(FormService.REACT_APP_NEW_API_FEEDER_LISTS);
  };

  public getPhaseConnectionLists = async (
    filterLists = {},
    status = "",
    page = 1,
    formType = ""
  ) => {
    let filterQuery = "";
    filterQuery += `page=${page}&`;
    if (
      status === "0" ||
      status === "1" ||
      status === "2" ||
      status === "5" ||
      status === "11" ||
      status === "9"
    ) {
      filterQuery += `status=${status}&`;
    }
    if (formType) {
      filterQuery += `form_type=${formType}&`;
    }

    for (let key in filterLists) {
      if (filterLists[key]) {
        if (key === "mobile") {
          // change mobile to contact for query
          filterQuery += `contact=${filterLists[key]}&`;
        } else {
          filterQuery += `${key}=${filterLists[key]}&`;
        }
      }
    }
    const query = filterQuery
      ? `${FormService.NEW_CONNECTIONS}?${filterQuery}`
      : FormService.NEW_CONNECTIONS;
    // const query = filterQuery
    // ? `${FormService.THREEPHASE_API}?${filterQuery}`
    // : FormService.THREEPHASE_API;
    return await requests.get(query);
  };

  public getThreePhaseConnectionLists = async (
    filterLists = {},
    status = "",
    page = 1,
    formType = ""
  ) => {
    let filterQuery = "";
    filterQuery += `page=${page}&`;
    if (
      status === "0" ||
      status === "1" ||
      status === "2" ||
      status === "5" ||
      status === "11" ||
      status === "9"
    ) {
      filterQuery += `status=${status}&`;
    }
    if (formType) {
      filterQuery += `form_type=${formType}&`;
    }

    for (let key in filterLists) {
      if (filterLists[key]) {
        if (key === "mobile") {
          // change mobile to contact for query
          filterQuery += `contact=${filterLists[key]}&`;
        } else {
          filterQuery += `${key}=${filterLists[key]}&`;
        }
      }
    }

    const query = filterQuery
      ? `${FormService.THREEPHASE_API}?${filterQuery}`
      : FormService.THREEPHASE_API;
    return await requests.get(query);
  };

  public saveThreePhase = async () => {};

  public downloadThreePhase = async (filter = {}) => {
    return await requests.post(
      FormService.REACT_APP_THREEPHASE_DOWNLOAD,
      filter
    );
  };

  public saveFeederIssue = async (request) => {
    return await requests.post(FormService.FEEDER_ISSUE_SAVE_API, request);
  };
  public updateFeederIssue = async (request) => {
    return await requests.post(
      FormService.REACT_APP_NEW_API_UPDATE_FEEDER_VALUES,
      request
    );
  };

  public getFeederData = async (id) => {
    const url = `${FormService.REACT_APP_NEW_API_FEEDER_LISTS}/${id}`;
    return await requests.get(url);
  };
  public getUserRegisterFormData = async () => {
    const url = `${FormService.REACT_APP_USER_REGISTER_FORM_DATA_API}`;
    return await requests.get(url);
  };
  public getComplainReportLists = async (filterUrl) => {
    const url = `${FormService.REACT_APP_COMPLAIN_REPORT_FILTER}?${filterUrl}`;
    return await requests.get(url);
  };
  public getFeederReportLists = async (payload) => {
    const url = `${FormService.REACT_APP_FEEDER_REPORT_FILTER}`;
    return await requests.post(url, payload);
  };
  public getComplainAllReportLists = async (type, payload) => {
    const url: any =
      type === "subject"
        ? process.env.REACT_APP_COMPLAIN_REPORT_SUBJECT_FILTER
        : type === "province"
        ? process.env.REACT_APP_COMPLAIN_REPORT_PROVINCE_FILTER
        : type === "branch"
        ? process.env.REACT_APP_COMPLAIN_REPORT_BRANCH_FILTER
        : "";

    return await requests.post(url, payload);
  };
  public getFeederAllReportLists = async (type, payload) => {
    const url: any =
      type === "subject"
        ? process.env.REACT_APP_FEEDER_REPORT_DATE_FILTER
        : type === "province"
        ? process.env.REACT_APP_FEEDER_REPORT_PROVINCE_FILTER
        : type === "branch"
        ? process.env.REACT_APP_FEEDER_REPORT_BRANCH_FILTER
        : "";

    return await requests.post(url, payload);
  };
  public saveThreephase = async (request, method = "") => {
    return await requests.post(FormService.THREEPHASE_API, request);
  };

  public updateThreephase = async (request, reg_id) => {
    return await requests.post(
      `${FormService.THREEPHASE_API}/${reg_id}`,
      request
    );
  };

  public addEstimationFileThreephase = async (request, id) => {
    return await requests.post(
      `${FormService.REACT_APP_THREEPHASE_FILE_ESTIMATION_ADD}/${id}`,
      request
    );
  };

  public rejectEstimationThreephase = async (request, id) => {
    const formData = new URLSearchParams();

    formData.append("rejection_status", request.rejection_status);

    const config = "application/x-www-form-urlencoded";

    return await requests.put(
      `${FormService.REACT_APP_THREEPHASE_FILE_ESTIMATION_ADD}/${id}`,
      formData,
      config
    );
  };

  public getPhaseConnectionByRegId = async (reg_id) => {
    return await requests.get(`${FormService.THREEPHASE_API}/${reg_id}`);
  };

  public getPhaseConnectionByRegIdDetail = async (reg_id) => {
    return await requests.get(
      `${FormService.THREEPHASE_API}/get_detail/${reg_id}`
    );
  };

  public getThreePhaseReport = async (filterUrl) => {
    return await requests.get(
      `${FormService.REACT_APP_THREEPHASE_REPORT_YEAR}?${filterUrl}`
    );
  };

  public getNewConnectionReport = async (filterUrl) => {
    return await requests.get(
      `${FormService.NEW_CONNECTION_REPORT_YEAR}?${filterUrl}`
    );
  };

  public getThreePhaseReportByProvince = async (filterUrl) => {
    return await requests.post(
      `${FormService.REACT_APP_THREEPHASE_REPORT_PROVINCE}`,
      filterUrl
    );
  };

  public getNewConnectionReportByProvince = async (filterUrl) => {
    return await requests.post(
      `${FormService.NEW_CONNECTION_REPORT_PROVINCE}`,
      filterUrl
    );
  };

  public updateThreePhaseFileStatus = async (request, id) => {
    return await requests.post(
      `${FormService.REACT_APP_THREEPHASE_FILE_STATUS_UPDATE}/${id}`,
      request
    );
  };

  public downloadYearThreePhaseReport = async (request) => {
    return await requests.post(
      `${FormService.REACT_APP_THREEPHASE_REPORT_PROVINCE_DOWNLOAD}`,
      request
    );
  };

  public downloadProvinceThreePhaseReport = async (request) => {
    return await requests.post(
      `${FormService.REACT_APP_THREEPHASE_REPORT_YEAR_DOWNLOAD}`,
      request
    );
  };

  public downloadZippedThreePhaseFiles = async (threePhaseId) => {
    return await requests.get(
      `${FormService.REACT_APP_THREEPHASE_FORM_ZIPPED_DOWNLOAD}/${threePhaseId}`
    );
  };

  public getFeederShutdown = async (filterParams = {}) => {
    let filterQuery = "";
    if (filterParams) {
      for (let param in filterParams) {
        filterQuery += `${param}=${filterParams[param]}&`;
      }
    }
    return await requests.get(
      `${FormService.REACT_APP_FEEDER_SHUTDOWN}?${filterQuery}`
    );
  };

  public getGrid = async (filterParams = {}) => {
    let filterQuery = "";
    if (filterParams) {
      for (let param in filterParams) {
        filterQuery += `${param}=${filterParams[param]}&`;
      }
    }
    return await requests.get(
      `${FormService.REACT_APP_FEEDER_GRID}?${filterQuery}`
    );
  };

  public addFeederShutdown = async (request) => {
    return await requests.post(
      `${FormService.REACT_APP_FEEDER_SHUTDOWN}`,
      request
    );
  };

  public addGridFeeder = async (request) => {
    return await requests.post(`${FormService.REACT_APP_FEEDER_GRID}`, request);
  };

  public updateShutdownStatus = async (request) => {
    return await requests.post(
      `${FormService.REACT_APP_FEEDER_SHUTDOWN_STATUS_UPDATE}`,
      request
    );
  };
  public updateGridStatus = async (request, id) => {
    return await requests.put(
      `${FormService.REACT_APP_FEEDER_GRID}/${id}`,
      request
    );
  };

  public getShutdownReport = async (year = "") => {
    return await requests.get(
      `${FormService.REACT_APP_FEEDER_SHUTDOWN_REPORT}?year=${year}`
    );
  };
  public getShutdownReportTypeWise = async () => {
    return await requests.get(
      `${FormService.REACT_APP_FEEDER_SHUTDOWN_REPORT_TYPEWISE}`
    );
  };
  public getGridReport = async (year = "") => {
    return await requests.get(
      `${FormService.REACT_APP_FEEDER_GRID_REPORT}?year=${year}`
    );
  };
  public getGridReportTypeWise = async () => {
    return await requests.get(
      `${FormService.REACT_APP_FEEDER_SHUTDOWN_REPORT_TYPEWISE}`
    );
  };

  public checkContactNumber = async (contact_no) => {
    return await requests.post(FormService.CHECK_CONTACT_NO_API, contact_no);
  };
  public getNewConnectionByRegisterId = async (reg_id) => {
    return await requests.get(`${FormService.NEW_CONNECTION}/${reg_id}`);
  };

  public getNewConnectionDetailByRegisterId = async (reg_id) => {
    return await requests.get(
      `${FormService.NEW_CONNECTION}/get_detail/${reg_id}`
    );
  };

  public changeFileStatus = async (file_id, statusBody) => {
    return await requests.post(
      `${FormService.NEW_CONNECTION}/status/update/${file_id}`,
      statusBody
    );
  };
  public saveEstimationFile = async (file_id, estimationBody) => {
    return await requests.post(
      `${FormService.NEW_CONNECTION}/update_estimation/${file_id}`,
      estimationBody
    );
  };

  public savePaymentFile = async (file_id, payBody) => {
    return await requests.post(
      `${FormService.NEW_CONNECTION}/update_payment/${file_id}`,
      payBody
    );
  };
  public changeNewConnectionStatus = async (statusBody) => {
    return await requests.post(
      `${FormService.NEW_CONNECTION_STATUS_UPDATE}`,
      statusBody
    );
  };
  public getConnectionByRegId = async (regId) => {
    return await requests.get(`${FormService.NEW_CONNECTION}/${regId}`);
  };

  public createConnection = async (formDatas) => {
    return await requests.post(`${FormService.NEW_CONNECTION}`, formDatas);
  };

  public updateConnection = async (formDatas, id) => {
    return await axios.patch(
      `https://neaapi.inferica.com/api/new-connection/${id}`,
      formDatas
    );
  };

  public downloadZippedConnectionFIles = async (id) => {
    return await requests.get(
      `${FormService.NEW_CONNECTION}/download_zip/${id}`
    );
  };

  public getNotices = async () => {
    return await requests.get(`${FormService.NOTICE_API}s`);
  };

  public createNotice = async (formData) => {
    return await requests.post(`${FormService.NOTICE_API}`, formData);
  };

  public updateNotice = async (formData, id) => {
    return await requests.put(`${FormService.NOTICE_API}/${id}`, formData);
  };

  public getNoticeById = async (id) => {
    return await requests.get(`${FormService.NOTICE_API}/${id}`);
  };

  public getActiveNotices = async () => {
    return await requests.get(`${FormService.NOTICE_API}s/active`);
  };
}

export default new FormService();
