import { requests } from "./requests";
import authStore from "../stores/authStore";
export class AuthService {
  private static LOGIN_API: string = process.env.REACT_APP_NEW_API_LOGIN || "";
  private static AUTH_CHECK: string = process.env.REACT_APP_NEW_API_AUTH || "";
  public login = async (request: object) => {
    return await requests.post(AuthService.LOGIN_API, request);
  };
  public checkAuth = async () => {
    return await requests.get(AuthService.AUTH_CHECK);
  };

  public hasPermission = (slug: string) => {
    // if (slug === "complain-subject-add") return false;

    const userInfo: any = authStore?.userInfo;
    const permissionLists: any = userInfo?.data;

    return permissionLists.map((p) => p.slug).includes(slug);
  };
}
export default new AuthService();
