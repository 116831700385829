import { requests } from "./requests";
export class CompalinService {
  private static REACT_APP_NEW_API_COMPLAIN_LISTS: string =
    process.env.REACT_APP_NEW_API_COMPLAIN_LISTS || "";
  private static REACT_APP_NEW_API_FEEDER_LISTS: string =
    process.env.REACT_APP_NEW_API_FEEDER_LISTS || "";
  private static REACT_APP_NEW_API_FEEDER_ISSUE_LISTS: string =
    process.env.REACT_APP_NEW_API_FEEDER_ISSUE_LISTS || "";
  private static REACT_APP_DELETE_CONTACT: string =
    process.env.REACT_APP_DELETE_CONTACT || "";
  private static REACT_APP_DELETE_COMPLAIN_MESSAGE: string =
    process.env.REACT_APP_DELETE_COMPLAIN_MESSAGE || "";
  private static REACT_APP_ALL_COMPLAIN: string =
    process.env.REACT_APP_ALL_COMPLAIN || "";

  public getComplainLists = async (filters) => {
    let params: any = [];
    if (filters) {
      for (let key in filters) {
        if (filters[key] != "") params.push(`${key}=${filters[key]}`);
      }
    }
    let parameters = params.length > 0 ? params.join("&") : "";
    const url = `${CompalinService.REACT_APP_NEW_API_COMPLAIN_LISTS}?${parameters}`;
    return await requests.get(url);
  };
  public getFeederIssueLists = async () => {
    return await requests.get(
      CompalinService.REACT_APP_NEW_API_FEEDER_ISSUE_LISTS
    );
  };
  public getFeederLists = async () => {
    return await requests.get(CompalinService.REACT_APP_NEW_API_FEEDER_LISTS);
  };
  public removeContactNumber = async (payload) => {
    const res = await requests.post(
      CompalinService.REACT_APP_DELETE_CONTACT,
      payload
    );
    return res;
  };
  public removeComplainMessage = async (payload) => {
    return await requests.post(
      CompalinService.REACT_APP_DELETE_COMPLAIN_MESSAGE,
      payload
    );
  };
  public getAllComplain = async (payload) => {
    return await requests.post(CompalinService.REACT_APP_ALL_COMPLAIN, payload);
  };
}
export default new CompalinService();
