import { action, observable, autorun } from "mobx";
import AuthService from "../services/authService";

import complainStore from "./complainStore";

export class AuthStore {
  @observable public userInfo: Object | undefined = undefined;
  @observable public isAuthorized: Boolean | undefined = undefined;
  @observable public errorText: string | null = null;
  @observable public _formIsSubmitting: Boolean = false;
  @observable public _successLogin: Boolean = false;
  @observable public JWT_TOKEN: any = undefined;
  private tokenName = "NEA-JWT-TOKEN";

  @action
  public setUserInfo = async (info: Object) => {
    this.userInfo = info;
  };
  public getUserInfo = () => {
    return this.userInfo;
  };

  @action
  private checkAuth = async () => {
    try {
      const response = await AuthService.checkAuth();
      this.isAuthorized =
        response.status == 200 && !!response.data ? true : false;
      this.setUserInfo(response.data);
    } catch (e) {
      this.isAuthorized = false;
    }
  };
  init = autorun(() => {
    this.JWT_TOKEN = window.localStorage.getItem(this.tokenName);
    this.JWT_TOKEN ? this.checkAuth() : (this.isAuthorized = false);
  });
  @action
  public getAuthToken() {
    this.JWT_TOKEN = window.localStorage.getItem(this.tokenName);
    return this.JWT_TOKEN;
  }
  @action
  public logOutUser = () => {
    window.localStorage.removeItem(this.tokenName);
    this.isAuthorized = false;
    this.errorText = null;
    this._formIsSubmitting = false;
    this._successLogin = false;
    this.userInfo = undefined;
    window.location.reload();
  };
  @action
  public reset = () => {
    // this.userInfo = undefined;
    // this.isAuthorized = undefined;
    // this.errorText = null;
    // this._formIsSubmitting = false;
    this._successLogin = false;
    // this.JWT_TOKEN = undefined;
  };

  @action
  public loginSubmit = async (request: object) => {
    this._formIsSubmitting = true;
    this.errorText = null;
    try {
      const response: any = await AuthService.login(request);
      if (!!response && response.data.success) {
        complainStore.init();

        this.updateToken(response.data.token, () => {
          this._successLogin = true;
          this.checkAuth();
          setTimeout(() => {
            this.isAuthorized = true;
          }, 500);
        });
      }
    } catch (e) {
      this.isAuthorized = false;
      this.errorText = "Wrong Email/Username or Password";
    }
    this._formIsSubmitting = false;
  };

  public updateToken(token, callback) {
    this.JWT_TOKEN = token;
    const result: any = window.localStorage.setItem(this.tokenName, token);
    if (result && callback) {
      result.then(callback);
    } else if (callback) {
      callback();
    }
  }
}
export default new AuthStore();
