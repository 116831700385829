import { requests } from "./requests";
export class CommonService {
  private static BRANCH_SAVE_API: string =
    process.env.REACT_APP_BRANCH_SAVE_API || "";
  private static BRANCH_GET_API: string =
    process.env.REACT_APP_BRANCH_SAVE_API || "";
  private static CONTACT_NUMBER_API: string =
    process.env.REACT_APP_CONTACT_NUMBER_API || "";
  private static REACT_APP_SUBJECT_API: string =
    process.env.REACT_APP_SUBJECT_API || "";
  private static REACT_APP_SUBSTATION_API: string =
    process.env.REACT_APP_SUBSTATION_API || "";
  private static REACT_APP_SUBSTATIONUPDATE_API: string =
    process.env.REACT_APP_SUBSTATIONUPDATE_API || "";
  public static REACT_APP_USER_REGISTER_API: string =
    process.env.REACT_APP_USER_REGISTER_API || "";
  public static REACT_APP_USER_LISTS: string =
    process.env.REACT_APP_USER_LISTS || "";
  public static REACT_APP_UPDATE_PROFILE_API: string =
    process.env.REACT_APP_UPDATE_PROFILE_API || "";
  public static REACT_APP_COMPLAIN_MESSAGE_API: string =
    process.env.REACT_APP_COMPLAIN_MESSAGE_API || "";
  public static REACT_APP_COUNTS: string = process.env.REACT_APP_COUNTS || "";
  public static REACT_APP_CURRENT_DATE: string =
    process.env.REACT_APP_CURRENT_DATE || "";
  public static REACT_APP_PROVINCE: string =
    process.env.REACT_APP_PROVINCE || "";
  public static REACT_APP_PERMISSION_LISTS: string =
    process.env.REACT_APP_PERMISSION_LISTS || "";
  public static REACT_APP_ADD_PERMISSION: string =
    process.env.REACT_APP_ADD_PERMISSION || "";
  public static REACT_APP_USER_PERMISSION: string =
    process.env.REACT_APP_USER_PERMISSION || "";

  public static REACT_APP_ROLE_SAVE: string =
    process.env.REACT_APP_ROLE_SAVE || "";

  public static REACT_APP_ROLE_UPDATE: string =
    process.env.REACT_APP_ROLE_UPDATE || "";

  public static REACT_APP_ROLE_LIST: string =
    process.env.REACT_APP_ROLE_LIST || "";

  public saveBranch = async (request) => {
    return await requests.post(CommonService.BRANCH_SAVE_API, request);
  };
  public getBranchLists = async (id = null) => {
    const url = id
      ? `${CommonService.BRANCH_GET_API}?id=${id}`
      : CommonService.BRANCH_GET_API;
    return await requests.get(url);
  };
  public saveContactNumber = async (request) => {
    return await requests.post(CommonService.CONTACT_NUMBER_API, request);
  };
  public getContactNumberLists = async (id) => {
    const url = id
      ? `${CommonService.CONTACT_NUMBER_API}?id=${id}`
      : CommonService.CONTACT_NUMBER_API;
    return await requests.get(url);
  };
  public saveSubject = async (request) => {
    return await requests.post(CommonService.REACT_APP_SUBJECT_API, request);
  };
  public getSubjectLists = async (id) => {
    const url = id
      ? `${CommonService.REACT_APP_SUBJECT_API}?id=${id}`
      : CommonService.REACT_APP_SUBJECT_API;
    return await requests.get(url);
  };
  public saveComplainMessage = async (request) => {
    return await requests.post(
      CommonService.REACT_APP_COMPLAIN_MESSAGE_API,
      request
    );
  };
  public getSubStationLists = async (id = null) => {
    let url = CommonService.REACT_APP_SUBSTATION_API;
    url = id ? `${url}/${id}` : url;
    return await requests.get(url);
  };
  public saveSubStation = async (request) => {
    return await requests.post(CommonService.REACT_APP_SUBSTATION_API, request);
  };
  public updateSubStation = async (request) => {
    return await requests.post(
      CommonService.REACT_APP_SUBSTATIONUPDATE_API,
      request
    );
  };
  public saveUser = async (request) => {
    const res = await requests.post(
      CommonService.REACT_APP_USER_REGISTER_API,
      request
    );
    return res;
  };
  public saveProvince = async (request) => {
    const res = await requests.post(CommonService.REACT_APP_PROVINCE, request);
    return res;
  };
  public getProvince = async (id = null) => {
    const url = id
      ? `${CommonService.REACT_APP_PROVINCE}?id=${id}`
      : CommonService.REACT_APP_PROVINCE;
    return await requests.get(url);
  };

  public updateProfile = async (request) => {
    const res = await requests.post(
      CommonService.REACT_APP_UPDATE_PROFILE_API,
      request
    );
    return res;
  };

  public saveRole = async (request) => {
    const res = await requests.post(CommonService.REACT_APP_ROLE_SAVE, request);
    return res;
  };

  public updateRole = async (request) => {
    const res = await requests.post(
      CommonService.REACT_APP_ROLE_UPDATE,
      request
    );
    return res;
  };

  public getUserLists = async (id) => {
    const url = id
      ? `${CommonService.REACT_APP_USER_LISTS}?id=${id}`
      : CommonService.REACT_APP_USER_LISTS;
    return await requests.get(url);
  };
  public getComplainMessageLists = async (id) => {
    const url = id
      ? `${CommonService.REACT_APP_COMPLAIN_MESSAGE_API}?id=${id}`
      : CommonService.REACT_APP_COMPLAIN_MESSAGE_API;
    return await requests.get(url);
  };
  public getCountReports = async () => {
    return await requests.get(CommonService.REACT_APP_COUNTS);
  };
  public getCurrentNepaliDate = async () => {
    return await requests.get(CommonService.REACT_APP_CURRENT_DATE);
  };
  public getPermissionLists = async () => {
    // alert(CommonService.REACT_APP_PERMISSION_LISTS);
    return await requests.get("/api/permissions");
  };
  public savePermission = async (payload) => {
    return await requests.post(CommonService.REACT_APP_ADD_PERMISSION, payload);
  };
  public getUserPermission = async (user_id) => {
    return await requests.get("/api/user-permission" + "?user_id=" + user_id);
  };

  public getRoleList = async () => {
    return await requests.get(CommonService.REACT_APP_ROLE_LIST);
  };

  public getRoleById = async (id) => {
    return await requests.get(CommonService.REACT_APP_ROLE_LIST + "/" + id);
  };

  public getComplainMapsByProvince = async (provinceId) => {
    return await requests.get(
      "/api/complain/?location=all&province=" + provinceId
    );
  };
}
export default new CommonService();
