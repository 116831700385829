import React from "react";
import LogoImg from "../images/nea-round.png";
const LoadingView: React.SFC<any> = props => {
  const topStyle: any = !!props.customTop ? props.customTop : { top: "50%" };
  return (
    <div
      style={topStyle}
      id="preloader"
      aria-busy="true"
      aria-label="Loading, please wait."
      role="progressbar"
    >
      <img className="icon" src={LogoImg} alt="loading" />
    </div>
  );
};
export default React.memo(LoadingView);
